<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          id="campos-filtros"
          :campos="camposEntidade"
          :baseDados="entidade"
          @loading="loading = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          id="btn-save-relatorio"
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="getDadosEntidade()"
          :loading="loading"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-eye-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              {{ entidade | toUpperCase }}
              {{ bdgd.ordinaria ? 'Ordinária' : '' }}
              {{ bdgd.data_registro | formatToMonth }} v{{ bdgd.versao }}
            </div>
          </template>
          <v-row>
            <v-col
              cols="12"
              v-if="!loading"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    style="float: right !important"
                    class="mt-n10"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="black"
                      :loading="loadingExportarConsulta"
                      @click="dialogExportarConsulta = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="22"> mdi-download-circle-outline </v-icon>
                    </v-btn>
                  </div>
                </template>
                Exportar consulta
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="this.entidadeGeografica && this.entidadeTipoPonto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    style="float: right !important"
                    class="mt-n10"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="black"
                      @click="openLocationCreationModal"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="22"> mdi-plus </v-icon>
                    </v-btn>
                  </div>
                </template>
                Novo
              </v-tooltip>
              <general-progress-bars
                class="mt-8 mb-8"
                :items="downloadProgressData"
                prefixText="Baixando Resultado"
              />
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="mt-3 mb-3 py-1 px-1"
              >
                <span>
                  Esta tela exibe no máximo
                  <strong>{{ limit | parseNumberToIntegerBR }}</strong> de um
                  total de
                  <strong>{{
                    numRegistrosEntidade | parseNumberToIntegerBR
                  }}</strong>
                  registros na entidade. Sua consulta retornou
                  <strong>{{ numRegistros | parseNumberToIntegerBR }}</strong>
                  registros.
                </span>
                <span>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/relatorios/edit')"
                    >Clique aqui</a
                  >
                  caso queira criar um relatório
                </span>
                <span v-if="entidadeGeografica"
                  >ou
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/mapas/layers-personalizados/edit')"
                    >clique aqui</a
                  >
                  para criar uma camada para plotar em um mapa
                  personalizado</span
                >.
              </v-alert>
            </v-col>
            <v-col
              cols="12"
              class="mt-1"
              v-if="!loading && entidadeGeografica"
            >
              <visualizar-mapa
                :entidadeBdgd="entidade"
                :bdgdVersao="bdgd"
                :geoserverLayer="geoserverLayer"
                :geoserverCqlFilter="geoserverCqlFilter"
                :geoserverOgcFilter="geoserverOgcFilter"
                :flyToX="coordenadas.longitude"
                :flyToY="coordenadas.latitude"
              />
            </v-col>
            <v-col
              cols="12"
              class="mt-1"
            >
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                :loading="loading"
                :hide-default-footer="loading"
                :options="{ multiSort: false }"
                :sort-by="['gid']"
                loading-text="Carregando tabela"
                no-data-text="Nenhum registro disponível"
                no-results-text="Nenhum registro correspondente encontrado"
                :mobile-breakpoint="10"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      <v-btn
                        color="error"
                        min-width="0"
                        class="px-1 ml-n1"
                        fab
                        icon
                        x-small
                        @click="openDialogDelete(row.item.gid)"
                      >
                        <v-icon small> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                    <td
                      v-for="(dado, coluna) in row.item"
                      :key="coluna + row.item.gid"
                    >
                      <editar-dado-entidade
                        :entidadeBdgd="entidade"
                        :id="row.item.gid"
                        :coluna="coluna"
                        :dado="dado"
                        :tipoDado="getTipoColuna(coluna)"
                        :edicaoHabilitada="userIsAdmin"
                        @notEditableClick="
                          (data) => onNotEditableClick(data, row)
                        "
                        :style="{
                          'text-decoration': isTableCellLocationEditable({
                            coluna
                          })
                            ? 'underline'
                            : 'initial'
                        }"
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <dialog-delete
                :dialog.sync="dialogDelete"
                @submitTriggered="excluirLinha"
              >
                <template slot="title">
                  Excluir linha #{{
                    dialogDeleteData.id | parseNumberToIntegerBR
                  }}
                </template>
                <template slot="body">
                  Tem certeza que deseja excluir a linha #{{
                    dialogDeleteData.id | parseNumberToIntegerBR
                  }}
                  da entidade {{ entidade | toUpperCase }}?
                </template>
              </dialog-delete>
            </v-col>
          </v-row>
        </base-material-card>
        <v-dialog
          v-model="dialogExportarConsulta"
          max-width="450px"
          @click:outside="dialogExportarConsulta = false"
        >
          <v-card>
            <v-card-title class="headline-custom">
              Exportar consulta para:
            </v-card-title>
            <v-card-text style="font-size: 15px !important">
              <v-radio-group
                v-model="formatoExportacao"
                mandatory
                row
              >
                <v-radio
                  label="XLSX"
                  value="XLSX"
                />
                <v-radio
                  v-if="entidadeGeografica"
                  label="Shapefile"
                  value="SHAPE"
                />
              </v-radio-group>
              <v-text-field
                v-model="maximoLinhasExportadas"
                label="Máximo de linhas a serem exportadas"
                type="number"
                :min="1"
                :max="limitePermitidoParaExportacao"
                autofocus
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="dialogExportarConsulta = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!exportacaoConsultaHabilitada"
                color="green darken-1"
                @click="exportarConsulta"
              >
                Exportar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <CadastroLocalizacao
      :key="rowLocationKey"
      :item="rowLocation"
      @update:item="onLocationUpdate"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import AuthService from '@/services/AuthService';
import BasesDadosService from '@/services/BasesDadosService';
import BdgdVersionsService from '@/services/BdgdVersionsService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';

export default {
  mixins: [camposFiltrosMixins],
  components: {
    CadastroLocalizacao: () => import('./CadastroLocalizacao.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue'),
    CamposFiltros: () => import('@/components/relatorios/CamposFiltros'),
    VisualizarMapa: () => import('./VisualizarMapa.vue'),
    EditarDadoEntidade: () => import('./EditarDadoEntidade.vue'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },
  data: () => ({
    bdgd: {
      id: 0,
      data_registro: null,
      versao: 1,
      ordinaria: false
    },
    userIsAdmin: false,
    loading: false,
    loadingExportarConsulta: false,
    downloadProgressData: {},
    headers: [],
    items: [],
    limit: 1000,
    numRegistros: 0,
    numRegistrosEntidade: 0,
    camposEntidade: [],
    select: null,
    entidadeGeografica: false,
    entidadeTipoPonto: false,
    entidadeTipoLinha: false,
    entidadeTipoPoligono: false,
    geoserverLayer: null,
    geoserverCqlFilter: null,
    geoserverOgcFilter: null,
    dialogDelete: false,
    dialogDeleteData: { id: 0 },
    dialogExportarConsulta: false,
    formatoExportacao: 'XLSX',
    maximoLinhasExportadas: 5000,
    limitePermitidoParaExportacao: 100000,
    rowLocationKey: null,
    rowLocation: null
  }),
  mounted() {
    this.getUserIsAdmin();
    this.getBdgd();
    this.getDadosEntidade();
    this.getCamposEntidade();
  },
  computed: {
    bdgdVersaoId() {
      return this.$route.params.bdgdVersaoId;
    },
    entidade() {
      return this.$route.params.entidade;
    },
    coordenadas() {
      let [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;
      if (!(this.items && this.items.length >= 1)) {
        return {
          longitude: Number(longitude),
          latitude: Number(latitude)
        };
      }
      if (this.items[0]['latitude'] && this.items[0]['longitude']) {
        latitude = this.items[0]['latitude'];
        longitude = this.items[0]['longitude'];
      } else if (this.items[0]['latitude1'] && this.items[0]['longitude1']) {
        latitude = this.items[0]['latitude1'];
        longitude = this.items[0]['longitude1'];
      } else if (this.items[0]['latitude2'] && this.items[0]['longitude2']) {
        latitude = this.items[0]['latitude2'];
        longitude = this.items[0]['longitude2'];
      }
      return {
        longitude: Number(longitude),
        latitude: Number(latitude)
      };
    },
    camposDicionarioDadosAneel() {
      return this.camposEntidade.filter((campo) =>
        this.$store.getters.campoExiste(campo.column)
      );
    },
    exportacaoConsultaHabilitada() {
      return (
        Number(this.maximoLinhasExportadas) > 0 &&
        this.maximoLinhasExportadas <= this.limitePermitidoParaExportacao
      );
    }
  },
  methods: {
    onLocationUpdate(data) {
      this.getDadosEntidade();
    },

    onNotEditableClick(data, row) {
      if (!this.isTableCellLocationEditable(data)) return;
      this.rowLocation = { ...row.item };
    },

    isTableCellLocationEditable(data) {
      if (
        !this.entidadeGeografica ||
        !['latitude', 'longitude'].includes(data.coluna)
      )
        return false;
      if (!this.entidadeTipoPonto) return false;
      return true;
    },

    openLocationCreationModal() {
      this.rowLocation = {};
    },

    async getUserIsAdmin() {
      this.userIsAdmin = await AuthService.userIsAdmin();
    },
    getBdgd() {
      BdgdVersionsService.getById(this.bdgdVersaoId)
        .then((response) => (this.bdgd = response))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar os dados da versão da BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    getDadosEntidade() {
      this.loading = true;
      const filtros = this.$refs.refCamposFiltros
        ? this.$refs.refCamposFiltros.exportCampos()
        : {};
      EntidadesBdgdService.getDadosEntidade(
        this.entidade,
        this.bdgdVersaoId,
        filtros,
        this.limit
      )
        .then(({ data }) => {
          this.items = [];
          this.headers = [
            {
              sortable: false,
              text: null,
              value: 'actions',
              class: 'pl-4'
            }
          ];
          data.colunas.forEach((coluna) =>
            this.headers.push({
              value: coluna,
              text: coluna,
              divider: false,
              type: data.tiposDados[coluna]
            })
          );
          data.dados.forEach((linha) => this.items.push(linha));
          this.select = data.select;
          this.entidadeGeografica = data.entidadeGeografica;
          this.entidadeTipoPonto = data.entidadeTipoPonto;
          this.entidadeTipoLinha = data.entidadeTipoLinha;
          this.entidadeTipoPoligono = data.entidadeTipoPoligono;
          this.geoserverLayer = data.geoserverLayer;
          this.geoserverCqlFilter = data.geoserverCqlFilter;
          this.geoserverOgcFilter = data.geoserverOgcFilter;
          this.numRegistros = data.numRegistros;
          this.numRegistrosEntidade = data.numRegistrosEntidade;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao recuperar as linhas importadas para a entidade ' +
              this.entidade.toUpperCase() +
              '.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    getCamposEntidade() {
      BasesDadosService.getBasesDadosCampos(this.entidade)
        .then((camposEntidade) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.camposEntidade = camposEntidade.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
          this.carregaDadosTabelasDicionarioDadosAneel();
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao recuperar campos da entidade selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    carregaDadosTabelasDicionarioDadosAneel() {
      if (!this.camposDicionarioDadosAneel) return;
      this.camposDicionarioDadosAneel.forEach((campo) => {
        const tabela = this.$store.getters.getTabelaPorCampo(
          this.entidade,
          campo.column
        );
        const dadosTabela = this.$store.getters.getDadosTabela(tabela);
        if (!dadosTabela.length)
          this.$store.dispatch('carregaDadosTabela', { tabela });
      });
    },
    exportarConsulta() {
      this.dialogExportarConsulta = false;
      this.loadingExportarConsulta = true;

      const id = dayjs(new Date()).format('YYYYMMDDHHmm');
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      const filtros = this.$refs.refCamposFiltros
        ? this.$refs.refCamposFiltros.exportCampos()
        : {};
      return this.formatoExportacao === 'SHAPE'
        ? this.exportarConsultaParaShapefile(id, filtros)
        : this.exportarConsultaParaXlsx(id, filtros);
    },
    exportarConsultaParaShapefile(id, filtros) {
      const nomeArquivo = `consulta-${this.entidade.toLowerCase()}-${id}.zip`;
      EntidadesBdgdService.exportaDadosEntidadeParaShapefile(
        this.entidade,
        this.bdgdVersaoId,
        filtros,
        this.maximoLinhasExportadas,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com o resultado da consulta.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
          this.loadingExportarConsulta = false;
        });
    },
    exportarConsultaParaXlsx(id, filtros) {
      const nomeArquivo = `consulta-${this.entidade.toLowerCase()}-${id}.xlsx`;
      EntidadesBdgdService.exportaDadosEntidade(
        this.entidade,
        this.bdgdVersaoId,
        filtros,
        this.maximoLinhasExportadas,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo xlsx com o resultado da consulta.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
          this.loadingExportarConsulta = false;
        });
    },
    getTipoColuna(coluna) {
      const colIndex = this.headers.findIndex(({ text }) => text === coluna);
      return this.headers[colIndex].type;
    },
    openDialogDelete(id) {
      this.dialogDeleteData = { id };
      this.dialogDelete = true;
    },
    excluirLinha() {
      const linhaId = this.dialogDeleteData.id;
      EntidadesBdgdService.deleteById(this.entidade, linhaId)
        .then(() => {
          const index = this.items.findIndex((item) => item.gid === linhaId);
          let items = [...this.items];
          items.splice(index, 1);
          this.items = items;
          this.dialogDelete = false;
          this.$toast.success(`Linha #${linhaId} removida com sucesso.`, '', {
            position: 'topRight'
          });
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Ocorreu um erro ao tentar remover a linha #${linhaId} da entidade.`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
